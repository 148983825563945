import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAK2z6YPB6N7AdtJ2Ev4yqHhLGWk3zcXYo",
    authDomain: "baby-c6627.firebaseapp.com",
    projectId: "baby-c6627",
    storageBucket: "baby-c6627.appspot.com",
    messagingSenderId: "112013924159",
    appId: "1:112013924159:web:84b263c66c61e4710c258a",
    measurementId: "G-HMWT5F86B2"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
