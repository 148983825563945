import React from 'react'
import ReactPlayer from 'react-player'
import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Video = () => {
    const navigate = useNavigate();

    const onEnded = () => {
        navigate('/result');
    }

    return (
        <Grid container justifyContent="center">
            <Card sx={{minWidth: 500}}>
                <CardMedia
                    component="img"
                    height="140"
                    image="/assets/1.jpg"
                />
                <CardContent>
                    <ReactPlayer
                        onEnded={onEnded}
                        width='400px;'
                        height='400px'
                        url='/assets/video.mp4'
                        controls={true}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
}

export default Video;
