import React from 'react'
import Video from './components/Video';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Results from './components/Result';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material';
import theme from './utils/theme';

const App = () => {
    return (
            <ThemeProvider theme={theme}>
                <Container>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/video" element={<Video/>}/>
                            <Route path="/result" element={<Results/>}/>
                        </Routes>
                    </BrowserRouter>
                </Container>
            </ThemeProvider>
    );
};

export default App;
