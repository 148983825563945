import { createTheme } from '@mui/material';
import { blue, pink } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: {
            main: pink.A200
        }
    },
});

export default theme;
