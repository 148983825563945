import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, TextField, Typography } from '@mui/material';
import GirlIcon from '@mui/icons-material/Girl';
import BoyIcon from '@mui/icons-material/Boy';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const [error, setError] = useState(false);
    const [name, setName] = useState();
    const votesCollectionRef = collection(db, "votes");
    const navigate = useNavigate();

    const addVote = async (gender) => {
        if (!name) {
            setError(true);
        } else {
            await addDoc(votesCollectionRef, {name: name, gender: gender});
            navigate('/video');
        }
    }

    return (
        <Grid container justifyContent="center">
            <Card sx={{width: 500}}>
                <CardMedia
                    component="img"
                    height="140"
                    image="/assets/1.jpg"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Baby Moelands
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Hallo, welkom op mijn eigen website, en dit is niet zomaar een website. Vandaag ga je te weten komen
                        of ik een jongen of een meisje ben.
                    </Typography>
                    <br/>
                    <Typography variant="body2" color="text.secondary">
                        Maar voordat ik dit aan je vertel zou ik het leuk vinden als je een gokje waagt.
                    </Typography>
                    <br/>
                    <TextField fullWidth label="Wat is je naam" variant="outlined" error={error} onChange={e => setName(e.target.value)}/>
                    <br/>
                </CardContent>
                <CardActions>
                    <Button
                        onClick={() => addVote('b')}
                        variant="contained"
                        color="primary"
                        startIcon={<BoyIcon/>}
                        fullWidth
                    >
                        Jongen
                    </Button>
                    <Button
                        onClick={() => addVote('g')}
                        variant="contained"
                        color="secondary"
                        startIcon={<GirlIcon/>}
                        fullWidth
                    >
                        Meisje
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default Home;
