import React, { useEffect, useState } from 'react';
import { db } from '../utils/firebase';
import { collection, getDocs } from 'firebase/firestore'
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import LinearProgress from '@mui/material/LinearProgress';

const Result = () => {
    const [votes, setVotes] = useState([]);
    const votesCollectionRef = collection(db, 'votes');

    const fetchVotes = async () => {
        const data = await getDocs(votesCollectionRef);
        setVotes(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    useEffect(() => {
        fetchVotes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (votes.length === 0) {
        return <></>
    }

    const percentage = (gender) => {
        const genderCount = votes.filter(vote => vote.gender === gender);

        return Math.round((genderCount.length / votes.length) * 100);
    }

    return (
        <>
            <Grid container justifyContent="center">
                <ConfettiExplosion floorWidth={1600} colors={['#f48fb1', '#e91e63', '#f8bbd0']} duration={10000}/>
            </Grid>
            <Grid container justifyContent="center">
                <Card sx={{width: 500}}>
                    <CardMedia
                        component="img"
                        height="140"
                        image="/assets/2.jpg"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" color="secondary">
                            Ik ben een meisje!!!
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Lis krijgt er dus een zusje bij!! Wat dachten de andere mensen?
                        </Typography>
                        <br/>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Jongen ({percentage('b')}%)</strong>
                        </Typography>
                        <LinearProgress variant="determinate" value={percentage('b')}/>
                        <br/>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Meisje ({percentage('g')}%)</strong>
                        </Typography>
                        <LinearProgress variant="determinate" value={percentage('g')} color="secondary"/>
                    </CardContent>
                </Card>
            </Grid>
        </>

    )
};

export default Result;
